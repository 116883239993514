import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

export default function Group(props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M24.9227 0C27.4676 0 29.5381 2.07044 29.5381 4.61538C29.5381 7.16031 27.4676 9.23075 24.9227 9.23075C22.3778 9.23075 20.3073 7.16031 20.3073 4.61538C20.3073 2.07044 22.3778 0 24.9227 0Z" />
      <path d="M21.2305 10.1543H28.6152C29.8028 10.1543 30.769 11.1205 30.769 12.3082V19.6928C30.769 20.776 29.9653 21.6749 28.9229 21.8247V29.8467C28.9229 31.0342 27.9567 32.0004 26.769 32.0004H23.0767C21.8891 32.0004 20.9229 31.0342 20.9229 29.8465V21.8247C19.8805 21.6749 19.0767 20.7759 19.0767 19.6927V12.3081C19.0767 11.1205 20.0429 10.1543 21.2305 10.1543Z" />
      <path d="M7.69249 0C10.2374 0 12.3079 2.07044 12.3079 4.61538C12.3079 7.16031 10.2374 9.23075 7.69249 9.23075C5.14755 9.23075 3.07711 7.16031 3.07711 4.61538C3.07711 2.07044 5.14755 0 7.69249 0Z" />
      <path d="M1.3283 24.2021L3.19905 20.4605C2.40686 20.142 1.84618 19.3658 1.84618 18.4611V12.3072C1.84618 11.1196 2.81236 10.1533 4.00005 10.1533H11.3847C12.5723 10.1533 13.5385 11.1195 13.5385 12.3072V18.4611C13.5385 19.3658 12.9778 20.142 12.1856 20.4605L14.0563 24.2021C14.1994 24.4882 14.1842 24.8281 14.016 25.1003C13.8478 25.3724 13.5506 25.5381 13.2307 25.5381H11.6923V29.8458C11.6923 31.0334 10.7261 31.9996 9.53842 31.9996H5.84611C4.65848 31.9996 3.69224 31.0334 3.69224 29.8458V25.5381H2.1538C1.83393 25.5381 1.5368 25.3724 1.36855 25.1003C1.20049 24.8281 1.18524 24.4883 1.3283 24.2021Z" />
    </SvgIcon>
  )
}
